import React from 'react'
import Helmet from 'react-helmet'
import Header from '../header/header'
import Footer from '../footer/footer'
import './layout.sass'

const Layout = ({ children, noEmail, className }) => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <Header />
      <main className={className}>
        {children}
      </main>
      <Footer noEmail={noEmail} />
    </>
  )
}

export default Layout
