import React, { Component } from 'react'
import { Link } from 'gatsby'
import Nav from '../nav/nav'
import logo from 'images/hanko-logo.svg'
import MenuIcon from 'images/icon-mobile-menu.inline.svg'
import closeIcon from 'images/icon-close-modal.svg'
import { CSSTransition } from 'react-transition-group'
import './header.sass'

class Header extends Component {

  constructor(props) {
    super(props)

    this.state = {
      mobileMenuOpen: false
    }
  }

  toggleMenu(openClose) {
    const mobileMenuOpen = typeof openClose === 'boolean'
      ? openClose
      : !this.state.mobileMenuOpen
    mobileMenuOpen
      ? document.body.classList.add('body--no-scroll')
      : document.body.classList.remove('body--no-scroll')
    this.setState({ mobileMenuOpen })
  }

  render() {
    return (
      <header className="Header">
        <div className="Header__wrap">
          <Link
            className="Header__link"
            to="/">
            <img
              className="Header__logo"
              src={logo}
              alt="Maison Hanko logo" />
          </Link>
          <button
            onClick={e => {e.preventDefault(); this.toggleMenu()}}
            className="Header__mobile-toggle">
            <MenuIcon />
          </button>
          <div className="Header__nav">
            <Nav alignedRight />
          </div>
          <CSSTransition
            in={this.state.mobileMenuOpen}
            unmountOnExit
            timeout={300}
            classNames="a-modal">
            <div className="Header__mobile-menu">
              <button
                className="Header__mobile-menu-close"
                onClick={e => {e.preventDefault(); this.toggleMenu()}}>
                <img
                  src={closeIcon}
                  alt="Close icon to close mobile menu" />
              </button>
              <Nav
                mobileNav
                closeMenu={() => this.toggleMenu(false)} />
            </div>
          </CSSTransition>
        </div>
      </header>
    )
  }

}

export default Header
