import React from 'react'
import { Link } from 'gatsby'
import chevron from 'images/icon-chevron.svg'
import './email.sass'

const Email = () => (
  <div className="Email">
    <div className="u-wrap">
      <p className="Email__content">
        Get in touch.
        <br />
        <a
          className="Email__address"
          href="mailto:hello@maisonhanko.com">
          hello@maisonhanko.com
        </a>
      </p>
      <Link
        className="Email__view"
        to="/talent">
        <div className="Email__view-flex">
          View all talent
          <img
            className="Email__chevron"
            src={chevron}
            alt="Icon chevron pointing right" />
        </div>
      </Link>
    </div>
  </div>
)

export default Email
