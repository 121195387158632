import React from 'react'
import Helmet from 'react-helmet'

const Seo = ({
    title = 'Maison Hanko - A celebration of Japanese talent, curated by Nexus Studios.',
    description = 'Maison Hanko gives access to some of Japan’s most exciting creative voices in order to produce outstanding and authentic creative work.',
    ogTitle = title,
    ogDescription = description,
    ogImage = 'https://s3.us-east-2.amazonaws.com/maisonhanko.com/_assets/global/og-image.gif'
  }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
    </Helmet>
  )
}

export default Seo
