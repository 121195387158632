import React from 'react'
import Email from './email'
import { Link } from 'gatsby'
import hLogo from 'images/hanko-logo-sm.svg'
import nLogo from 'images/nexus-logo.svg'
import './footer.sass'

const Footer = ({ noEmail }) => (
  <footer className={`Footer ${noEmail ? 'Footer--no-email' : ''}`}>
    {!noEmail &&
      <Email />
    }
    <div className="Footer__main">
      <div className="u-wrap">
        <div className="Footer__inner">
          <div className="Footer__logos">
            <Link
              className="Footer__border"
              to="/">
              <img
                className="Footer__logo"
                src={hLogo}
                alt="Maison Hanko logo" />
            </Link>
            <a
              href="https://nexusstudios.com/work/arvr"
              target="_blank"
              rel="noopener noreferrer">
              <img
                className="Footer__logo"
                src={nLogo}
                alt="Nexus Studios logo" />
            </a>
          </div>
          <p className="Footer__subcopy">
            &copy; 2019 Nexus Studios. All Rights Reserved. <a
              href="https://nexusstudios.com/legal/"
              target="_blank"
              rel="noopener noreferrer">
              Website Terms.
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
