import React from 'react'
import { Link } from 'gatsby'
import logo from 'images/hanko-logo-icon.svg'
import './nav.sass'
import './mobile-nav.sass'

const Nav = ({ alignedRight, mobileNav, closeMenu }) => (
  <nav className={`
      ${mobileNav ? 'MobileNav a-modal-inner' : 'Nav'}
      ${alignedRight ? 'Nav--aligned-right' : ''}
    `}>
    <div>
      {mobileNav &&
          <img
            className="MobileNav__icon"
            src={logo}
            alt="Maison Hanko Logo as an icon" />
      }
        <ul>
        <li>
          <Link
            to="/talent"
            onClick={ () => mobileNav ? closeMenu() : null }>
            Talent
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            onClick={ () => mobileNav ? closeMenu() : null }>
            About
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            onClick={ () => mobileNav ? closeMenu() : null }>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  </nav>
)

export default Nav
